import React, { useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import styles from './Search.module.scss';
const { REACT_APP_API_URL } = process.env;

const Search = ({searchItemClicked, lang}) => {
    const [searchResultsState, setSearchResultsState] = useState({
        posts: [],
        videos: [],
        isLoaded: false
    });
    const [searchTermState, setSearchTermState] = useState('');
    const urlPrefix = lang==='en'?'/en':'';
    useEffect(() => {
        getArticles(searchTermState);
    }, [searchTermState]);

    useEffect(() => {
        const getPosts = axios.get(`${REACT_APP_API_URL}${urlPrefix}/wp-json/wp/v2/posts?per_page=3`);
        const getVideos = axios.get(`${REACT_APP_API_URL}${urlPrefix}/wp-json/wp/v2/videos?per_page=3`);
        Promise.all([getPosts, getVideos]).then(axios.spread((...res) => {
            setSearchResultsState({
                posts: res[0].data,
                videos: res[1].data,
                isLoaded: true
            });
        }))
        .catch(err => console.log(err));
    }, [urlPrefix]);

    const getArticles = (text) => {
        if (text == null || text.trim().length < 3) {
            setSearchResultsState({
                posts: [],
                videos: [],
                isLoaded: true
            });
            return
        }
        const getPosts = axios.get(`${REACT_APP_API_URL}${urlPrefix}/wp-json/wp/v2/posts?per_page=3&search=${text}`);
        const getVideos = axios.get(`${REACT_APP_API_URL}${urlPrefix}/wp-json/wp/v2/videos?per_page=3&search=${text}`);
        Promise.all([getPosts, getVideos]).then(axios.spread((...res) => {
            setSearchResultsState({
                posts: res[0].data,
                videos: res[1].data,
                isLoaded: true
            });
        }))
        .catch(err => console.log(err));
    }

    const {posts, videos, isLoaded} = searchResultsState;

    return (
        <div className={styles['search-bar']}>
            <input
                type="text"
                placeholder={`${lang==='en'?'Search...':'Sök...'}`}
                onChange={event => {
                    setSearchTermState(event.target.value)
                }}
            />
            {
                isLoaded ?
                    <>
                        <div className={styles['search-results']}>
                            <div className={styles['search-heading']}>{lang==='en'?'Posts':'Inlägg'}</div>
                            {posts.filter((item) => {
                                if (searchTermState == "") {
                                   return item;
                                }
                                else if (item.title.rendered.toLowerCase().includes(searchTermState.toLowerCase())) {
                                    return item;
                                }
                            }).map((item, index) => (
                                <div className={styles['item']} key={index}>
                                    <Link to={`${urlPrefix}/post/${item.slug}`} onClick={searchItemClicked}>
                                        <div className={styles['post-preview-image']}>
                                            <img
                                                src={item.acf.article_preview_image.url}
                                                alt={item.acf.article_preview_image.alt}
                                            />
                                        </div>
                                        <p dangerouslySetInnerHTML={{__html: item.title.rendered}} className={styles['article-title']}></p>
                                    </Link>
                                </div>
                            ))}
                            <div className={styles['search-heading']}>{lang==='en'?'Videos':'Filmer'}</div>
                            {videos.filter((item1) => {
                                if (searchTermState == "") {
                                   return item1;
                                }
                                else if (item1.title.rendered.toLowerCase().includes(searchTermState.toLowerCase())) {
                                    return item1;
                                }
                            }).map((item1, index) => (
                                <div className={styles['item']} key={index}>
                                    <Link to={`${urlPrefix}/video/${item1.slug}`} onClick={searchItemClicked}>
                                        <div className={styles['video-preview-image']}>
                                            <img
                                                src={item1.acf.article_preview_image.url}
                                                alt={item1.acf.article_preview_image.alt}
                                            />
                                        </div>
                                        <p dangerouslySetInnerHTML={{__html: item1.title.rendered}} className={styles['article-title']}></p>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </>
                : <h3>Loading...</h3>
            }
        </div>
    )
}

export default Search;
