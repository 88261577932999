import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player/vimeo';
import {useRouteMatch} from "react-router-dom";
import styles from './BackgroundVideo.module.scss';
const { REACT_APP_API_URL } = process.env;

const BackgroundVideo = () => {
    const [videoState, setVideoState] = useState({
        backgroundVideo: '',
        isLoaded: false
    });

    useEffect(() => {
        axios.get(`${REACT_APP_API_URL}/wp-json/wp/v2/pages/51`)
            .then(res =>{
                const bgVideo = res.data.acf.homepage_background.url;

                setVideoState({
                    backgroundVideo: bgVideo,
                    isLoaded: true
                });
            })
            .catch(err => console.log(err));
    }, []);


    const {backgroundVideo, isLoaded} = videoState;
    const matchPost = useRouteMatch("/post/:slug") || useRouteMatch("/en/post/:slug");
    const matchVideo = useRouteMatch("/video/:slug") || useRouteMatch("/en/video/:slug");

    return (
        isLoaded ?
            <div className={`${styles['parallax-video']} ${matchPost || matchVideo ? styles['hide-background-video'] : ''}`}>
                <ReactPlayer
                    url={backgroundVideo}
                    controls={false}
                    muted={true}
                    playing={true}
                    loop={true}
                    width="auto"
                    height="auto"
                />
            </div> :
            null
    )
}

export default BackgroundVideo;
