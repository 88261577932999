import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player/vimeo';
import styles from './Homepage.module.scss';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import parse from "html-react-parser";
const { REACT_APP_API_URL } = process.env;

const Homepage = ({lang='sv', setEnUrl, setSvUrl}) => {
    const [homepageState, setHomepageState] = useState({
        sortedArticlesArray: [],
        backgroundTitle: '',
        backgroundSubtitle: '',
        isLoaded: false
    });
    const [metadataState, setMetadataState] = useState('');
    const [videosClickedArray, setVideosClickedArray] = useState([]);
    const urlPrefix = lang==='en'?'/en':'';

    useEffect(() => {
        setEnUrl('/en');
        setSvUrl('/');
        axios.get(`${REACT_APP_API_URL}${urlPrefix}/wp-json/wp/v2/pages/${lang==='en'?'98':'52'}`)
            .then(res =>{
                const bgTitle = res.data.acf.background_title;
                const bgSubtitle = res.data.acf.background_subtitle;
                const simpleDescriptions = res.data.acf.description_repeater_field.map (item => {
                    return (
                        {...item, type: "singleDescription"}
                    )
                });
                const oneItemArticles = res.data.acf.one_item_repeater.map(item => {
                    return (
                        {...item, type: "oneItemArticles"}
                    )
                });
                const twoItemsArticles = res.data.acf.two_items_repeater.map(item => {
                    return (
                        {...item, type: "twoItemsArticles"}
                    )
                });
                const threeItemsArticles = res.data.acf.three_items_repeater.map(item => {
                    return (
                        {...item, type: "threeItemsArticles"}
                    )
                });
                const mergedArticlesArray=[
                    ...oneItemArticles,
                    ...twoItemsArticles,
                    ...threeItemsArticles,
                    ...simpleDescriptions
                ].sort((a, b) =>
                    a.position_number > b.position_number ? 1 : -1
                );

                const finalMergedArray = mergedArticlesArray.map((item, index) => {
                    if(index>0) {
                        if(item.type=="oneItemArticles" || item.type=="singleDescription") {
                            if(mergedArticlesArray[index-1].type=="oneItemArticles" || mergedArticlesArray[index-1].type=="singleDescription") {
                                return {
                                    ...item, newType: "filledBack"
                                }
                            }
                        }
                    }
                    return item;
                })

                setHomepageState({
                    sortedArticlesArray: finalMergedArray,
                    backgroundTitle: bgTitle,
                    backgroundSubtitle: bgSubtitle,
                    isLoaded: true
                });
                setMetadataState(res.data.yoast_head);
            })
            .catch(err => console.log(err));
    }, [urlPrefix]);

    const {sortedArticlesArray, backgroundTitle, backgroundSubtitle, isLoaded} = homepageState;
    const updateClickedVideosArray = (id) => {
        setVideosClickedArray([...videosClickedArray, id])
    };
    const metatags = metadataState
    .replace('<meta property="og:url" content="https://bkbliforalder.rfsl.se','<meta property="og:url" content="https://bliforalder.rfsl.se')
    .replace('<meta property="og:url" content="http://bkbliforalder.rfsl.se','<meta property="og:url" content="https://bliforalder.rfsl.se')
    .split("\r\n").map(x=>x);

    return (
        <>
            <Helmet>
                <title>Start | RFSL</title>
                <meta name="description" content="Bli förälder - filmmaterial" />
                <link rel="alternate" hrefLang="sv-se" href="https://bliforalder.rfsl.se" />
                {parse(`${metatags}`)}
            </Helmet>
            {
                isLoaded ?
                <>
                    <div className={styles['background-video-content']}>
                        <h1 dangerouslySetInnerHTML={{__html: backgroundTitle}} />
                        <h2 dangerouslySetInnerHTML={{__html: backgroundSubtitle}} />
                    </div>
                    {sortedArticlesArray.map((item, index) =>
                        <React.Fragment key={index}>
                            {
                                item.type === "singleDescription" &&
                                <div className={`${styles['homepage-single-description']} ${item.newType=="filledBack" ? styles['smaller-space'] : ""}`}>
                                    <div className={styles.container}>
                                        <div dangerouslySetInnerHTML={{__html: item.description_field}} className={styles['single-description']}></div>
                                    </div>
                                </div>
                            }
                            {
                                item.type === "oneItemArticles" &&
                                item.one_item_first_item!="" && 
                                <div className={`${styles['homepage-one-item']} ${item.newType=="filledBack" ? styles['smaller-space'] : ""}`}>
                                    <div className={styles.container}>
                                        {item.one_item_first_item.map(insideItem => (
                                                <div key={insideItem.ID} className={styles.item}>
                                                    <div className={styles['box-description']}>
                                                        <div dangerouslySetInnerHTML={{__html: insideItem.post_excerpt}} className={styles['description']} />
                                                        {insideItem.post_type == "videos" 
                                                        ?
                                                            <Link to={`${urlPrefix}/video/${insideItem.post_name}`} className={styles['article-link']}>Läs mer</Link>
                                                        :
                                                            <Link to={`${urlPrefix}/post/${insideItem.post_name}`} className={styles['article-link']}>Läs mer</Link>
                                                        }
                                                    </div>
                                                    <div className={styles['box']}>
                                                        <div className={styles['preview-image']}>
                                                            {insideItem.post_type == "videos" ?
                                                                <div className={styles['player-wrapper']}>
                                                                    <ReactPlayer
                                                                        className={styles['react-player']}
                                                                        url={insideItem.acf.video_link.url}
                                                                        controls={true}
                                                                        light={true}
                                                                        width="100%"
                                                                        playing={true}
                                                                    />
                                                                </div> :
                                                                <img
                                                                    src={insideItem.acf.article_preview_image.url}
                                                                    alt={insideItem.acf.article_preview_image.alt}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                item.type === "twoItemsArticles" &&
                                item.two_items_first_item!="" && 
                                item.two_items_second_item!="" && 
                                <div className={styles['homepage-two-items']}>
                                    <div className={styles.container}>
                                        {item.two_items_first_item.map(insideItem1 => (
                                            <div key={insideItem1.ID} className={styles.item}>
                                                <div className={styles['box']}>
                                                    <div className={styles['preview-image']}>
                                                        {insideItem1.post_type == "videos" ?
                                                            <div className={styles['player-wrapper']}>
                                                                <ReactPlayer
                                                                    className={styles['react-player']}
                                                                    url={insideItem1.acf.video_link.url}
                                                                    controls={true}
                                                                    light={true}
                                                                    width="100%"
                                                                    playing={true}
                                                                />
                                                            </div> :
                                                            <img
                                                                src={insideItem1.acf.article_preview_image.url}
                                                                alt={insideItem1.acf.article_preview_image.alt}
                                                            />
                                                        }
                                                    </div>
                                                    {insideItem1.post_type == "videos" 
                                                    ?
                                                        <Link to={`${urlPrefix}/video/${insideItem1.post_name}`} className={styles['article-link']}>
                                                            <div dangerouslySetInnerHTML={{__html: insideItem1.post_title}} className={styles['title']}/>
                                                        </Link>
                                                    :
                                                        <Link to={`${urlPrefix}/post/${insideItem1.post_name}`} className={styles['article-link']}>
                                                            <div dangerouslySetInnerHTML={{__html: insideItem1.post_title}} className={styles['title']}/>
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                        {item.two_items_second_item.map(insideItem2 => (
                                            <div key={insideItem2.ID} className={styles.item}>
                                                <div className={styles['box']}>
                                                    <div className={styles['preview-image']}>
                                                        {insideItem2.post_type == "videos" ?
                                                            <div className={styles['player-wrapper']}>
                                                                <ReactPlayer
                                                                    className={styles['react-player']}
                                                                    url={insideItem2.acf.video_link.url}
                                                                    controls={true}
                                                                    light={true}
                                                                    width="100%"
                                                                    playing={true}
                                                                />
                                                            </div> :
                                                            <img
                                                                src={insideItem2.acf.article_preview_image.url}
                                                                alt={insideItem2.acf.article_preview_image.alt}
                                                            />
                                                        }
                                                    </div>
                                                    {insideItem2.post_type == "videos" 
                                                    ?
                                                        <Link to={`${urlPrefix}/video/${insideItem2.post_name}`} className={styles['article-link']}>
                                                            <div dangerouslySetInnerHTML={{__html: insideItem2.post_title}} className={styles['title']}/>
                                                        </Link>
                                                    :
                                                        <Link to={`${urlPrefix}/post/${insideItem2.post_name}`} className={styles['article-link']}>
                                                            <div dangerouslySetInnerHTML={{__html: insideItem2.post_title}} className={styles['title']}/>
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                item.type === "threeItemsArticles" &&
                                item.three_items_first_item!="" && 
                                item.three_items_second_item!="" && 
                                item.three_items_third_item!="" && 
                                <div className={styles['homepage-three-items']}>
                                    <div className={styles.container}>
                                        <div className={styles['small-boxes']}>
                                            {item.three_items_first_item.map(insideItem1 => (
                                                <div key={insideItem1.ID} className={styles.item}>
                                                    <div className={styles['box']}>
                                                        <div className={styles['preview-image']}>
                                                            {insideItem1.post_type == "videos" ?
                                                                <div className={styles['player-wrapper']}>
                                                                    <ReactPlayer
                                                                        className={styles['react-player']}
                                                                        url={insideItem1.acf.video_link.url}
                                                                        controls={true}
                                                                        light={true}
                                                                        width="100%"
                                                                        playing={true}
                                                                        onClickPreview={()=>updateClickedVideosArray(insideItem1.ID)}
                                                                    />
                                                                </div> :
                                                                <img
                                                                    src={insideItem1.acf.article_preview_image.url}
                                                                    alt={insideItem1.acf.article_preview_image.alt}
                                                                />
                                                            }
                                                        </div>
                                                        {videosClickedArray.indexOf(insideItem1.ID)===-1 &&
                                                        <div className={`${insideItem1.post_type == "videos" ? (`${styles['hidden-content']} ${styles['hidden-content-video']}`) : styles['hidden-content']}`}>
                                                                {insideItem1.post_type == "videos" 
                                                                ?
                                                                    <Link to={`${urlPrefix}/video/${insideItem1.post_name}`} className={styles['article-link']}>
                                                                        <div dangerouslySetInnerHTML={{__html: insideItem1.post_title}} className={styles['title']}/>
                                                                    </Link>
                                                                :
                                                                    <Link to={`${urlPrefix}/post/${insideItem1.post_name}`} className={styles['article-link']}>
                                                                        <div dangerouslySetInnerHTML={{__html: insideItem1.post_title}} className={styles['title']}/>
                                                                    </Link>
                                                                }
                                                            <div dangerouslySetInnerHTML={{__html: insideItem1.post_excerpt}} className={styles['box-description']} />
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                            {item.three_items_second_item.map(insideItem2 => (
                                                <div key={insideItem2.ID} className={styles.item}>
                                                    <div className={styles['box']}>
                                                        <div className={styles['preview-image']}>
                                                            {insideItem2.post_type == "videos" ?
                                                                <div className={styles['player-wrapper']}>
                                                                    <ReactPlayer
                                                                        className={styles['react-player']}
                                                                        url={insideItem2.acf.video_link.url}
                                                                        controls={true}
                                                                        light={true}
                                                                        width="100%"
                                                                        playing={true}
                                                                        onClickPreview={()=>updateClickedVideosArray(insideItem2.ID)}
                                                                    />
                                                                </div> :
                                                                <img
                                                                    src={insideItem2.acf.article_preview_image.url}
                                                                    alt={insideItem2.acf.article_preview_image.alt}
                                                                />
                                                            }
                                                        </div>
                                                        {videosClickedArray.indexOf(insideItem2.ID)===-1 &&
                                                        <div className={`${insideItem2.post_type == "videos" ? (`${styles['hidden-content']} ${styles['hidden-content-video']}`) : styles['hidden-content']}`}>
                                                                {insideItem2.post_type == "videos" 
                                                                ?
                                                                    <Link to={`${urlPrefix}/video/${insideItem2.post_name}`} className={styles['article-link']}>
                                                                        <div dangerouslySetInnerHTML={{__html: insideItem2.post_title}} className={styles['title']}/>
                                                                    </Link>
                                                                :
                                                                    <Link to={`${urlPrefix}/post/${insideItem2.post_name}`} className={styles['article-link']}>
                                                                        <div dangerouslySetInnerHTML={{__html: insideItem2.post_title}} className={styles['title']}/>
                                                                    </Link>
                                                                }
                                                            <div dangerouslySetInnerHTML={{__html: insideItem2.post_excerpt}} className={styles['box-description']} />
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className={styles['big-box']}>
                                            {item.three_items_third_item.map(insideItem3 => (
                                                <div key={insideItem3.ID} className={styles.item}>
                                                    <div className={styles['box']}>
                                                        <div className={styles['preview-image']}>
                                                            {insideItem3.post_type == "videos" ?
                                                                <div className={styles['player-wrapper']}>
                                                                    <ReactPlayer
                                                                        className={styles['react-player']}
                                                                        url={insideItem3.acf.video_link.url}
                                                                        controls={true}
                                                                        light={true}
                                                                        width="100%"
                                                                        playing={true}
                                                                        onClickPreview={()=>updateClickedVideosArray(insideItem3.ID)}
                                                                    />
                                                                </div> :
                                                                <img
                                                                    src={insideItem3.acf.article_preview_image.url}
                                                                    alt={insideItem3.acf.article_preview_image.alt}
                                                                />
                                                            }
                                                        </div>
                                                        {videosClickedArray.indexOf(insideItem3.ID)===-1 &&
                                                        <div className={`${insideItem3.post_type == "videos" ? (`${styles['hidden-content']} ${styles['hidden-content-video']}`) : styles['hidden-content']}`}>
                                                                {insideItem3.post_type == "videos" 
                                                                ?
                                                                    <Link to={`${urlPrefix}/video/${insideItem3.post_name}`} className={styles['article-link']}>
                                                                        <div dangerouslySetInnerHTML={{__html: insideItem3.post_title}} className={styles['title']}/>
                                                                    </Link>
                                                                :
                                                                    <Link to={`${urlPrefix}/post/${insideItem3.post_name}`} className={styles['article-link']}>
                                                                        <div dangerouslySetInnerHTML={{__html: insideItem3.post_title}} className={styles['title']}/>
                                                                    </Link>
                                                                }
                                                            <div dangerouslySetInnerHTML={{__html: insideItem3.post_excerpt}} className={styles['box-description']} />
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    )}
                </>
                : <h3>Loading...</h3>
            }
        </>
    )
}

export default Homepage;
