import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './PostPage.module.scss';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import parse from "html-react-parser";
import getTranslationUrls from '../../utils/translations';
import { FacebookShareButton, FacebookIcon } from "react-share";
const { REACT_APP_API_URL } = process.env;

const PostPage = ({lang='sv', setEnUrl, setSvUrl}) => {
    const pageParams = useParams();
    const [postPageState, setPostPageState] = useState({
        post: {},
        isLoaded: false
    });
    const [metadataState, setMetadataState] = useState('');
    const urlPrefix = lang==='en'?'/en':'';
    
    useEffect(() => {
        axios.get(`${REACT_APP_API_URL}${urlPrefix}/wp-json/wp/v2/posts?slug=${pageParams.slug}`)
            .then(res => {
                setPostPageState({
                    post: res.data ? res.data[0] : null,
                    isLoaded: true
                });
                setMetadataState(res.data[0].yoast_head);
                const {enUrl, svUrl} = getTranslationUrls(res.data[0].wpml_translations, res.data[0].wpml_current_locale, pageParams.slug, 'post');
                setEnUrl(enUrl);
                setSvUrl(svUrl);
            })
            .catch(err => console.log(err));
    }, [pageParams.slug]);

    const {post, isLoaded} = postPageState;
    const metatags = metadataState
    .replace('<meta property="og:url" content="https://bkbliforalder.rfsl.se/','<meta property="og:url" content="https://bliforalder.rfsl.se/post/')
    .replace('<meta property="og:url" content="http://bkbliforalder.rfsl.se/','<meta property="og:url" content="https://bliforalder.rfsl.se/post/')
    .split("\r\n").map(x=>x);
    const frontUrl = post.link?post.link.replace('bkbliforalder.rfsl.se/','bliforalder.rfsl.se/post/'):'';
    
    if (post==null) {
        return <h1 className={styles['not-found']}>Inlägget hittades inte</h1>
    }
    
    return (
        <>
            {
                isLoaded ?
                <>
                    <Helmet>
                        <title>{post.title.rendered} | RFSL</title>
                        <meta name="description" content={`${post.excerpt.rendered}`} />
                        {parse(`${metatags}`)}
                    </Helmet>
                    <div className={styles['main-part']}>
                        <div className={styles['main-part-inner']}>
                            <div className={styles['article-header']}>
                                <div className={styles['article-header-content']}>
                                    <div className={styles['image-container']}>
                                        <div
                                            style={{
                                                backgroundImage: `url(${post.acf.article_preview_image.url})`
                                            }}
                                        ></div>
                                    </div>
                                    <h1 dangerouslySetInnerHTML={{__html: post.title.rendered}}></h1>
                                </div>
                            </div>
                            <div className={styles['article-content']}>
                                <div className={styles['article-content-inner']}>
                                    <div dangerouslySetInnerHTML={{__html: post.content.rendered}}></div>
                                    <div className={styles['share-section']}>
                                        <h4>Dela på:</h4>
                                        <FacebookShareButton url={frontUrl} className={styles['facebook-share-btn']} >
                                            <FacebookIcon size={32} round={true}/>
                                        </FacebookShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> :
                <h3>Loading...</h3>
            }
        </>
    )
}

export default PostPage;
