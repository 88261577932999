import React, { useState, useEffect } from 'react';
import VideoItem from './VideoItem';
import styles from './Videos.module.scss';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
const { REACT_APP_API_URL } = process.env;

const Videos = ({lang='sv', setEnUrl, setSvUrl}) => {
    const [videosState, setVideosState] = useState({
        videos: [],
        isLoaded: false
    });
    const urlPrefix = lang==='en'?'/en':'';

    useEffect(() => {
        setEnUrl('/en/videos');
        setSvUrl('/videos');
        axios.get(`${REACT_APP_API_URL}${urlPrefix}/wp-json/wp/v2/videos`)
        .then(res => setVideosState({
            videos: res.data,
            isLoaded: true
        }))
        .catch(err => console.log(err));
    }, [urlPrefix]);

    const {videos, isLoaded} = videosState;

    return (
        <>
            <Helmet>
                <title>Videos | RFSL</title>
                <meta name="description" content="The videos page" />
            </Helmet>
            {
                isLoaded ?
                <>
                    <div className={styles['main-part']}>
                        <div className={styles['main-part-inner']}>
                            <div className={styles['articles-grid']}>
                                {videos.map(video => (
                                    <VideoItem key={video.id} video={video} lang={lang} />
                                ))}
                            </div>
                        </div>
                    </div>
                </> :
                <h3>Loading...</h3>
            }
        </>
    )
}

export default Videos;
