import React, { useState, useEffect, Fragment } from 'react';
import ReactPlayer from 'react-player/vimeo';
import axios from 'axios';
import styles from './VideoPage.module.scss';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import parse from "html-react-parser";
import getTranslationUrls from '../../utils/translations';
import { FacebookShareButton, FacebookIcon } from "react-share";
const { REACT_APP_API_URL } = process.env;

const VideoPage = ({lang='sv', setEnUrl, setSvUrl}) => {
    const pageParams = useParams();
    const [videoPageState, setVideoPageState] = useState({
        video: {},
        isLoaded: false
    });
    const [metadataState, setMetadataState] = useState('');
    const urlPrefix = lang==='en'?'/en':'';

    useEffect(() => {
        axios.get(`${REACT_APP_API_URL}${urlPrefix}/wp-json/wp/v2/videos?slug=${pageParams.slug}`)
            .then(res => { 
                if(res.data) {
                    setVideoPageState({
                        video: res.data[0],
                        isLoaded: true
                    });
                    setMetadataState(res.data[0].yoast_head);
                    const {enUrl, svUrl} = getTranslationUrls(res.data[0].wpml_translations, res.data[0].wpml_current_locale, pageParams.slug, 'video');
                    setEnUrl(enUrl);
                    setSvUrl(svUrl);
                }
            })
            .catch(err => console.log(err));
    }, [pageParams.slug]);
    
    const {video, isLoaded} = videoPageState;
    const metatags = metadataState
    .replace("https://bkbliforalder.rfsl.se/videos","https://bliforalder.rfsl.se/video")
    .replace("http://bkbliforalder.rfsl.se/videos","https://bliforalder.rfsl.se/video")
    .split("\r\n").map(x=>x);
    const frontUrl = video.link?video.link.replace('bkbliforalder.rfsl.se/videos/','bliforalder.rfsl.se/video/'):'';

    if (video==null) {
        return <h1 className={styles['not-found']}>Videon hittades inte</h1>
    }
    
    return (
        <>
            {
                isLoaded ?
                <>
                    <Helmet>
                        <title>{video.title.rendered} | RFSL</title>
                        <meta name="description" content={`${video.excerpt.rendered}`} />
                        {parse(`${metatags}`)}
                    </Helmet>
                    <div className={styles['main-part']}>
                        <div className={styles['main-part-inner']}>
                            <div className={styles['article-header']}>
                                <div className={styles['article-header-content']}>
                                    <h1 dangerouslySetInnerHTML={{__html: video.title.rendered}}></h1>
                                </div>
                            </div>
                            <div className={styles['article-content']}>
                                <div className={styles['article-content-inner']}>
                                    <div dangerouslySetInnerHTML={{__html: video.content.rendered}}></div>
                                    <div className={styles['player-wrapper']}>
                                        <ReactPlayer
                                            className={styles['react-player']}
                                            url={video.acf.video_link.url}
                                            controls={true}
                                            light={true}
                                            playing={true}
                                            width="100%"
                                        />
                                    </div>
                                    <div className={styles['video-link']}>
                                        <a href={video.acf.video_link.url} target="_blank" rel="nofollow noreferrer">Ladda ner filmen från Vimeo</a>
                                    </div>
                                    <div className={styles['share-section']}>
                                        <h4>Dela på:</h4>
                                        <FacebookShareButton url={frontUrl} className={styles['facebook-share-btn']} >
                                            <FacebookIcon size={32} round={true}/>
                                        </FacebookShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> :
                <h3>Loading...</h3>
            }
        </>
    )
}

export default VideoPage;
