import React, { useState, useEffect } from 'react';
import PostItem from './PostItem';
import axios from 'axios';
import styles from './Posts.module.scss';
import { Helmet } from 'react-helmet-async';
const { REACT_APP_API_URL } = process.env;

const Posts = ({lang='sv', setEnUrl, setSvUrl}) => {
    const [postsState, setPostsState] = useState({
        posts: [],
        isLoaded: false
    });
    const urlPrefix = lang==='en'?'/en':'';

    useEffect(() => {
        setEnUrl('/en/posts');
        setSvUrl('/posts');
        axios.get(`${REACT_APP_API_URL}${urlPrefix}/wp-json/wp/v2/posts`)
        .then(res => setPostsState({
            posts: res.data,
            isLoaded: true
        }))
        .catch(err => console.log(err));
    }, [urlPrefix]);

    const {posts, isLoaded} = postsState;

    return (
        <>
            <Helmet>
                <title>Inlägg | RFSL</title>
                <meta name="description" content="The Inlägg page" />
            </Helmet>
            {
                isLoaded ?
                <>
                    <div className={styles['main-part']}>
                        <div className={styles['main-part-inner']}>
                            <div className={styles['articles-grid']}>
                                {posts.map(post => (
                                    <PostItem key={post.id} post={post} lang={lang} />
                                ))}
                            </div>
                        </div>
                    </div>
                </> :
                <h3>Loading...</h3>
            }
        </>
    )
}

export default Posts;
