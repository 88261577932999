import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { slide as SlideMenu } from 'react-burger-menu';
import Search from '../Search/Search';
import Logo from '../../assets/rfsl-white-logo.png';
import './Menu.scss';

const Menu = ({enUrl, svUrl, lang}) => {
    const [menuState, setMenuState] = useState({
        menuItems: [],
        isLoaded: false
    });
    const [menuOpenState, setMenuOpenState] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/menu${lang==='en'?'en':''}`)
            .then(res => setMenuState({
                menuItems: res.data,
                isLoaded: true
            }))
            .catch(err => console.log(err));
    }, [lang]);

    const {menuItems, isLoaded} = menuState;

    const handleMenuClick = () => {
        setMenuOpenState(false);
    }
    const isMenuOpen = function(state) {
        setMenuOpenState( state.isOpen);
    };
    
    return (
        isLoaded ?
            <div id="main-navigation">
                <Link to={`${lang==='en'?'/en':'/'}`}>
                    <img src={Logo} className="site-logo" alt="Logo" />
                </Link>
                <div className="main-navigation-inner">
                    <div className="main-menu">
                        <SlideMenu
                         onStateChange={ isMenuOpen }
                            isOpen={menuOpenState}
                        >
                            <div className="menu-items">
                                {menuItems.map(item => (
                                        <Link
                                            key={item.ID}
                                            className="menu-item"
                                            to={item.url}
                                            onClick={handleMenuClick}
                                        >
                                            {item.title}
                                        </Link>
                                ))}
                                <div className="menu-languages">
                                    <Link to={svUrl} onClick={handleMenuClick}>
                                        <span className="language-title">SV</span>
                                    </Link>
                                    <Link to={enUrl} onClick={handleMenuClick}>
                                        <span className="language-title">EN</span>
                                    </Link>
                                </div>
                            </div>
                            <Search searchItemClicked={handleMenuClick} lang={lang}/>
                        </SlideMenu>
                    </div>
                </div>
            </div>
        : null
    )
}

export default Menu;