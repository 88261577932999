import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import styles from './Posts.module.scss';
const { REACT_APP_API_URL } = process.env;

const PostItem = ({lang='sv',...props}) => {
    const [postItemState, setPostItemState] = useState({
        imgUrl: '',
        isLoaded: false
    });
    const urlPrefix = lang==='en'?'/en':'';

    useEffect(() => {
        const {id:customFeaturedMedia} = props.post.acf.article_preview_image;
        const getImageUrl = axios.get(`${REACT_APP_API_URL}/wp-json/wp/v2/media/${customFeaturedMedia}`);
        Promise.all([getImageUrl]).then(axios.spread((...res) => {
            setPostItemState({
               imgUrl: res[0].data.media_details.sizes.full.source_url,
               isLoaded: true
            });
        }));
    }, [props.post.acf.article_preview_image]);

    const {slug, title, excerpt} = props.post;
    const {imgUrl, isLoaded} = postItemState;

    return (
        isLoaded ?
        <div className={styles['article-item']}>
            <Link to={`${urlPrefix}/post/${slug}`}>
                <img
                    style={{width: '100%'}}
                    src={imgUrl}
                    alt={title.rendered}
                />
            </Link>
            <div className={styles['article-item-content']}>
                <Link to={`${urlPrefix}/post/${slug}`}>
                    <h2 dangerouslySetInnerHTML={{__html: title.rendered}}></h2>
                </Link>
                <div dangerouslySetInnerHTML={{__html: excerpt.rendered}} />
            </div>
        </div> : null
    )

}

export default PostItem;
