import React, { useState } from 'react';
import {BrowserRouter as Router, Route, Switch, useRouteMatch} from 'react-router-dom';
import Videos from './components/Videos/Videos';
import VideoPage from './components/Videos/VideoPage';
import Posts from './components/Posts/Posts';
import PostPage from './components/Posts/PostPage';
import Homepage from './components/Homepage/Homepage';
import Menu from './components/Menu/Menu';
import './App.scss';
import BackgroundVideo from './components/BackgroundVideo/BackgroundVideo';
import { HelmetProvider } from 'react-helmet-async';

function RouteHelper({lang='sv', setEnUrl, setSvUrl}) {
  const match=useRouteMatch();
  const urlPrefix = match.url==='/'?'':match.url;
  return  (
    <Switch>
      <Route exact path={`${match.url==='/'?'/':match.url}`}>
        <Homepage lang={lang} setEnUrl={setEnUrl} setSvUrl={setSvUrl}/>
      </Route>
      <Route path={`${urlPrefix}/posts`}>
        <Posts lang={lang} setEnUrl={setEnUrl} setSvUrl={setSvUrl}/>
      </Route>
      <Route path={`${urlPrefix}/videos`}>
        <Videos lang={lang} setEnUrl={setEnUrl} setSvUrl={setSvUrl}/>
      </Route>
      <Route path={`${urlPrefix}/video/:slug`} >
        <VideoPage lang={lang} setEnUrl={setEnUrl} setSvUrl={setSvUrl}/>
      </Route>
      <Route path={`${urlPrefix}/post/:slug`}>
        <PostPage lang={lang} setEnUrl={setEnUrl} setSvUrl={setSvUrl}/>
      </Route>
    </Switch>
  )
}

function App() {
  const [enUrl, setEnUrl] = useState('/en');
  const [svUrl, setSvUrl] = useState('/');
  return (
    <HelmetProvider>
      <div className="App" id="outer-container">
        <Router>
          <div id="page-wrap">
            <BackgroundVideo />
            <Switch>
              <Route path="/en" >
                <Menu enUrl={enUrl} svUrl={svUrl} lang={'en'}/>
                <RouteHelper lang="en" setEnUrl={setEnUrl} setSvUrl={setSvUrl}/>
              </Route>
              <Route path="/">
                <Menu enUrl={enUrl} svUrl={svUrl} lang={'sv'}/>
                <RouteHelper setEnUrl={setEnUrl} setSvUrl={setSvUrl}/>
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
