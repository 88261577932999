const getTranslationUrls = (wpml_translations, wpml_current_locale, slug, pagePrefix) => {
    const translations = wpml_translations;
    const curLocale = wpml_current_locale==='en_US' ? 'en' : 'sv';

    if(curLocale==='en') {
        const enUrl = `/en/${pagePrefix}/${slug}`;
        const svTranslation = translations.length>0 ? translations.find(item => item.locale==='sv_SE') : null;
        const svUrl = svTranslation!==null?`/${pagePrefix}/${svTranslation.slug}`:'/';
        return {
            enUrl,
            svUrl
        }
    }

    const svUrl = `/${pagePrefix}/${slug}`;
    const enTranslation = translations.length>0 ? translations.find(item => item.locale==='en_US') : null;
    const enUrl = enTranslation!==null?`/en/${pagePrefix}/${enTranslation.slug}`:'/en';
    return {
        enUrl,
        svUrl
    }
}

export default getTranslationUrls;